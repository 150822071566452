//see https://stackoverflow.com/a/55388961/1136822
if (typeof EventTarget !== 'undefined') {
	const func = EventTarget.prototype.addEventListener;

	EventTarget.prototype.addEventListener = function (type, fn, capture) {
		this.func = func;
		capture = capture || {};
		if (capture === Object(capture)) {
			capture.passive = false;
		}
		this.func(type, fn, capture);
	};
}
