//---------- config ----------
var MG = {};
MG.config = MG_config || {};
MG.config.devMode = MG.config.devMode || false;
MG.config.baseUrl = MG.config.baseUrl || window.location.protocol + '//' + window.location.hostname;
MG.siteName = 'Memorial Green';
MG.sidrBreakpoint = 768;
MG.svgMapFullSizeBreakpoint = 1000;

//---------- custom log method ----------
window.log = function log() {
	if (!MG.config.devMode) {
		return;
	}
	log.history = log.history || [];
	log.history.push(arguments);
	if( this.console){
		items = Array.prototype.slice.call(arguments);
		if (items.length) {
			console.log( MG.siteName + ': ', Array.prototype.slice.call(arguments).shift() );
		} else {
			console.group( MG.siteName + ': ', items.shift());
			for (var i=0; i < items.length; i++) {
				console.log(items[i]);
			}
			console.groupEnd();
		}
	}
};

//---------- components ----------
/**
 * MG components.
 * @type {{init: Function}}
 */
MG.components = {
	clusterCarousel: function() {
		var $window = $(window);

		$('[data-component=clusterCarousel]').each(function() {

			var $fullSlider = $('[data-component=clusterFullSlider]');
			if ( $fullSlider.length ) {
				//reduce the full slider array to the first, since we are only expecting one
				$fullSlider = $fullSlider.eq(0);

				//get the card sliders
				var $cardSliders = $('[data-component=clusterCardSlider]');

				//set up the main carousel
				$fullSlider.slick({
					autoplay: true,
					autoplaySpeed: 5000,
					pauseOnHover: true,
					arrows: true,
					dots: false,
					swipe: true,
					infinite: true,
					speed: 1000,
					fade: true,
					cssEase: 'linear'
				})
					.each(function() {
						function equalizeFullSlideHeights() {
							var $this = $(this);
							var $track = $this.find('.slick-track');
							var $content = $track.find('.fullSlideContent');

							//equalize the slide heights
							$content.css({'paddingTop': '', 'height':''});
							var minSlideHeight = $track.height();
							$content.each(function(ind, el){
								var contentHeight = $(el).height();
								if ( contentHeight < minSlideHeight ) {
									$(el).css({	'paddingTop': Math.round((minSlideHeight - contentHeight) * .5)});
								}
							});
							$content.css('height', minSlideHeight);
						}
						$window.on('DOMContentLoaded load resize', debounce(equalizeFullSlideHeights.bind(this), 100, false) );
					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$cardSliders.trigger("midway:clusterSlideChange", [nextSlide]);
					});

				//set up the card carousels
				$cardSliders.each(function (index) {
					var speed = 1000; // Math.floor((Math.random() * 1000)+1000);
					// Change slides sequentially
					var delay = 500 * (index + 1);

					$(this).slick({
						autoplay: false,
						//autoplaySpeed: 0,
						pauseOnHover: false,
						arrows: false,
						dots: false,
						swipe: false,
						speed: speed,
						fade: true,
						cssEase: 'linear'
					}).on('midway:clusterSlideChange', function (event, nextSlide) {
						var $slider = $(this);
						setTimeout(function () {
							$slider.slick('slickGoTo', nextSlide);
						}, delay);
					});
					//when the main carousel changes, change the sub carousels too
				});
			}
		});
	},
	featuresSlider: function() {
		var $window = $(window);

		//set up the main carousel
		$('[data-component=featuresSlider]').slick({
			autoplay: true,
			autoplaySpeed: 6000,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			swipe: true,
			infinite: true,
			speed: 1000,
			fade: false
		})
			.each(function() {
				function equalizeFeatureSlideHeights() {
					var $this = $(this);
					var $track = $this.find('.slick-track');
					var $panelContainer = $track.find('.panelContainer');
					var $content = $track.find('.card--content');
					$panelContainer.css({'height':''});
					$content.css({'paddingTop': '', 'height':''});
					var trackHeight = $track.height();
					var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

					if ( viewportWidth > MG.sidrBreakpoint ) {
						$panelContainer.css('height', trackHeight);
					} else {
						var maxContentHeight = 0;
						$content.each(function(ind, el){
							maxContentHeight = Math.max(maxContentHeight, $(el).height());
						});

						$content.css('height', maxContentHeight);
					}

				}
				$window.on('DOMContentLoaded load resize', debounce(equalizeFeatureSlideHeights.bind(this), 100, false) );
			})
	},
	heroSlider: function() {
		$('[data-component=heroSlider]').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: true,
			arrows: true,
			dots: false,
			swipe: true,
			infinite: true,
			speed: 1000,
			fade: true
		})
	},
	imageSlider: function() {
		$('[data-component=imageSlider]').slick({
			autoplay: true,
			autoplaySpeed: 3000,
			pauseOnHover: true,
			arrows: true,
			dots: false,
			swipe: true,
			infinite: true,
			speed: 1000,
			fade: true
		})
	},
	videoLightboxes: function() {
		$('[href*=youtu],[href*=vimeo]').magnificPopup({
			mainClass: 'mfp-with-zoom',
			type: 'iframe',
			closeOnContentClick: false,
			closeOnBgClick: true,
			zoom: {
				enabled: true,
				duration: 300,
				easing: 'ease-in-out'
			},
			callbacks: {
				open: function () {
					$.sidr('close');
				}
			}
		});
	},
	sidr: function(){
		var $window = $(window),
			$html = $('html').eq(0);
		$('[data-component=mobileToggle]').sidr({
			source: function(){
				return $('.mobileNav').first().html();
			},
			side: 'left',
			displace: false,
			onOpen: function() {
				$html.addClass('sidr-open');
			},
			onClose: function() {
				$html.removeClass('sidr-open');
			}
			//speed: 0
		});
		function checkSidr() {
			//close sidr if needed
			var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
			if ( viewportWidth >= MG.sidrBreakpoint ) {
				$.sidr('close');
			}
		}
		$window.on('DOMContentLoaded load resize', debounce(checkSidr, 250, false) );

		$('[data-component=mobileClose]').click(function(){
			$.sidr('close');
			/*//start fading out the nav
			$html.removeClass('sidr-open');
			//close after slight delay to allow fading out
			setTimeout( function(){ $.sidr('close'); }, 500 );*/
		});
	},
	brokerContactForm: function() {
		var $brokerContactForm = $('[data-component=brokerContactForm]');

		if ( $brokerContactForm.length ) {
			$brokerContactForm.each(function(ind,ele){
				var $agentCheckbox = $(ele).find('.checkboxLabel').find('input').eq(0);
				var $formAgentInfo = $(ele).find('.formAgentInfo').eq(0);
				if ( $agentCheckbox.length && $formAgentInfo.length) {
					$agentCheckbox.change(function() {
						if ( this.checked ) {
							$formAgentInfo.removeClass('hidden');
						} else {
							$formAgentInfo.addClass('hidden');
						}
					});
					$agentCheckbox.trigger('change');
					$formAgentInfo.css('display','block');
				}
			});
		}
	},
	listingLightboxes: function() {
		$('[data-component=lightboxListing]').magnificPopup({
			type: 'image',
			image : {
				markup: '<div class="mfp-figure">'+
							'<div class="mfp-close"></div>'+
							'<div class="mfp-title"></div>'+
							'<figure><div class="mfp-img"></div></figure>'+
						'</div>',
				verticalFit: true,
				titleSrc : function(item) {
					var $listingLightboxContent = $(item.el).parent('.listing').find('.listingLightboxContent');

					//get the entry's title
					return $listingLightboxContent.attr('data-lightbox-title');
				}
			},

			mainClass : 'mfp-listing',
			closeOnContentClick: false,
			closeOnBgClick: false,
			callbacks: {
				buildControls: function() {
					if ( this.arrowLeft ) {
						// re-appends controls inside the main container
						this.content.append(this.arrowLeft.add(this.arrowRight));
					}
				},
				open: function () {
					$.sidr('close');
				},
				change: function() {
					var that = this;

					//get the mfp divs
					var $container = $(this.container).first();
					var $content = $(this.contentContainer);
					var $figure = $content.find('figure');
					var $close = $content.find('.mfp-close');

					//remove any previously added content
					$content.find('.listingLightboxContent').remove();

					//get the clicked element
					var $currentEl = $(this.currItem.el);
					//get the clicked element's bg class
					var bgClass = $currentEl.attr('data-bg-class');

					var isSvgLink = ($currentEl.attr('data-is-svg-link') == 'yes');

					//get the clicked elements listing content div
					var $listingLightboxContent = $([]);

					if ( isSvgLink ) {
						$content
							.append($currentEl.parent('.svgMapPopover').data('siteMap').lightboxHtml);
					} else {
						$listingLightboxContent = $currentEl.parent('.listing').find('.listingLightboxContent');

						//add the h2, move the close button, and add the listing content
						if ( $listingLightboxContent.length ) {
							$content
								.append($listingLightboxContent.prop('outerHTML'));
						}
					}

					//add the bg class
					$figure.addClass(bgClass);

					//close button click
					$close.click( this.close );

					$container.click(function(e){
						if ( e.target === $container.get(0) ) {
							that.close();
						}
					});
				}
			},
			gallery: {
				enabled: true,
				arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

				tPrev: 'Previous (Left arrow key)', // title for left button
				tNext: 'Next (Right arrow key)' // title for right button
			},
			closeMarkup : '<button title="%title%" type="button" class="mfp-close"><span class="icon-close"></span><span class="mfp-close-text">Close</span></button>'
		}).addClass('zoom-in');
	},
	threeColumnImages: function() {
		$('[data-component=threeColumnImages]').each(function() {
			//get the card sliders
			var $cardSliders = $('[data-component=threeColumnSlider]');

			//set up the card carousels
			$cardSliders.each(function() {
				var $cardSlider = $(this);

				if ( $cardSlider.find('.slideImgBg').length > 1 ) {
					var speed = Math.floor((Math.random() * 1000)+1000);
					$(this).slick({
						autoplay: true,
						pauseOnHover: false,
						arrows: false,
						dots: false,
						swipe: false,
						speed: speed,
						fade: true,
						cssEase: 'linear'
					});
				}
			});
		});
	},
	siteMap: function() {
		//console.log(MG_siteMap);

		var $siteMaps = $('[data-component=siteMap]');
		var $body = $('body').eq(0);
		var $window = $(window);

		var eventsHandler;

		eventsHandler = {
			haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
			, init: function(options) {
				var instance = options.instance
					, initialScale = 1
					, pannedX = 0
					, pannedY = 0;

				// Init Hammer
				// Listen only for pointer and touch events
				this.hammer = Hammer(options.svgElement, {
					inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
				});

				// Enable pinch
				this.hammer.get('pinch').set({enable: true});

				// Handle double tap
				this.hammer.on('doubletap', function(ev){
					instance.zoomIn()
				});

				// Handle pan
				this.hammer.on('panstart panmove', function(ev){
					// On pan start reset panned variables
					if (ev.type === 'panstart') {
						pannedX = 0;
						pannedY = 0;
					}

					// Pan only the difference
					instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY});
					pannedX = ev.deltaX
					pannedY = ev.deltaY
				});

				// Handle pinch
				this.hammer.on('pinchstart pinchmove', function(ev){
					// On pinch start remember initial zoom
					if (ev.type === 'pinchstart') {
						initialScale = instance.getZoom()
						instance.zoom(initialScale * ev.scale)
					}

					instance.zoom(initialScale * ev.scale)

				});

				// Prevent moving the page on some devices when panning over SVG
				options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
			}

			, destroy: function(){
				this.hammer.destroy()
			}
		};

		$siteMaps.each(function() {
			var $wrapper = $(this);
			var $svg = $wrapper.find('svg').eq(0);
			var svg = $svg[0];
			var $lots = $svg.find('[class=lot]');

			var $popover = $('<div class="svgMapPopover" style="display: none;"></div>');
			$body.append($popover);

			var panZoomEnabled = true;

			var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

			var panZoom = svgPanZoom(svg, {
				viewportSelector: '.svg-pan-zoom_viewport'
				, panEnabled: true
				, controlIconsEnabled: false
				, zoomEnabled: true
				, dblClickZoomEnabled: false
				, mouseWheelZoomEnabled: true
				, preventMouseEventsDefault: true
				, zoomScaleSensitivity: 0.2
				, minZoom: 0.25
				, maxZoom: 4
				, fit: false
				, contain: false
				, center: true
				, refreshRate: 'auto'
				, beforeZoom: function(){
					$popover.hide();

					if ( ! panZoomEnabled ) {
						$svg.removeClassSVG('svgBorder');
						return false;
					}
					else
					{
						$svg.addClassSVG('svgBorder');
					}
				}
				//, onZoom: function(){}
				, beforePan: function(oldPan, newPan){
					if ( ! panZoomEnabled ) {
						return false;
					}

					$svg.addClassSVG('svgBorder');
					$popover.hide();

					var sizes = this.getSizes();
					var gutterWidth = sizes.width - 100
						, gutterHeight = sizes.height - 100

						// Computed variables
						, leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth
						, rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom)
						, topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight
						, bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);

					var customPan = {};
					customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
					customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

					return customPan
				}
				//, onPan: function(){}
				, customEventsHandler: eventsHandler
			});

			$lots.addClassSVG('noClick lot-default');

			$lots.each(function(){
				var $lot = $(this);

				var id = $lot.attr('id');

				if (typeof(MG_siteMap.lots[id]) !== 'undefined') {
					var lot = MG_siteMap.lots[id];
					$lot.removeClassSVG('noClick');
					$lot.addClassSVG('clickable');

					if ( !! lot.builder.slug ) {
						$lot.removeClassSVG('lot-default');
						$lot.addClassSVG('lot-'+lot.builder.slug);
					}

					//lot click
					$lot.click(function(e) {
						var clickedLot = this;
						var $clickedLot = $(clickedLot);

						//populate popup
						var popoverHtml = '<p class="svgMapStatus">' + lot.status + '</p>';

						if ( !! lot.builder.slug ) {
							popoverHtml += '<p class="svgMapTitle">';
							if ( lot.builder.url != '#' ) {
								popoverHtml += '<a href="' + lot.builder.url + '" target="_blank">';
							}
							popoverHtml += lot.builder.title;
							if ( lot.builder.url != '#' ) {
								popoverHtml += '</a>';
							}
							popoverHtml += '</p>';
						}

						//add floorplan information, if it is available
						if (typeof(MG_siteMap.floorplans['_' + lot.floorplan]) !== 'undefined') {
							var floorplan = MG_siteMap.floorplans['_' + lot.floorplan];
							popoverHtml += floorplan.displayHtml;

							//assign the lightbox html to the popup div, so that it can be used by magnific popup on link click
							$popover.data('siteMap', {
								lightboxHtml : floorplan.lightboxHtml
							});
						}

						$popover.css('visibility', 'hidden').show();
						$popover.html( popoverHtml);

						//timeout used to ensure that the popover content is positioned and styled
						//fixes a bug where positions were sometimes incorrect
						window.setTimeout(function(){
							//set up the lightbox
							MG.components.listingLightboxes();

							//show the popover
							$popover.css('visibility', 'visible');

							//popup dimensions
							var popoverWidth = $popover.outerWidth();
							var popoverHeight = $popover.outerHeight();

							//clicked el position
							var elPos = $clickedLot.offset();
							var elBox = clickedLot.getBoundingClientRect();

							var newLeft = elPos.left - ( (popoverWidth - elBox.width) * .5 );
							var newTop = elPos.top - popoverHeight - 10;

							//position the popover
							$popover.css({
								left: newLeft,
								top: newTop
							});
						}, 50);
					});
				}
				else
				{
					$popover.hide();
				}
			});

			$window.on('DOMContentLoaded load resize', function(){
				$popover.hide();
			});

			$window.on('DOMContentLoaded load resize', debounce(function() {

				panZoomEnabled = true;

				panZoom.resize();
				panZoom.fit();
				panZoom.center();

				panZoomEnabled = ( viewportWidth <= MG.svgMapFullSizeBreakpoint );
			}, 250, false) );
		});
	},
	commercialMap: function() {
		var $commercialMaps = $('[data-component=commercialMap]');
		var $window = $(window);
		var activeClass = 'loc--open';
		var hoverClass = 'loc--hover';
		var enabledClass = 'loc--enabled';
		var popoverOpenClass = 'popover--open';
		var popoverHoverClass = 'popover--hover';
		var chosenOptions = {allow_single_deselect: true, disable_search_threshold: 50};

		if ($commercialMaps.length) {
			//load chosen
			head.js(
				{chosen:'/assets/js/chosen.jquery.min.js'},
				{choseCss:'/assets/styles/chosen.min.css'}
			);

			//set up each map
			$commercialMaps.each(function() {
				var $block = $(this);
				var $container = $block.find('.commercialMap-container').eq(0);
				var $svg = $container.find('svg').eq(0);
				var $categorySelect = $block.find('.commercialMap-categories').eq(0);
				var $businessSelect = $block.find('.commercialMap-businesses').eq(0);

				//the locations
				var $locs = $([]);

				//the popovers
				var $popovers = $([]);

				//whether we are in hover mode
				var isHoverMode = true;

				//get the json data
				var dataVar = $(this).attr('data-var');
				if (!dataVar || typeof(window[dataVar]) === 'undefined') {
					return true;
				}
				var data = window[dataVar];

				//loop through all of the businesses
				var businessIndex = 1;
				for (var key in data.businesses) {
					if (data.businesses.hasOwnProperty(key)) {
						var businessInfo = data.businesses[key];
						var $loc = $svg.find('#loc-'+businessInfo.location);
						$locs = $locs.add($loc);

						//assign class to active locations
						$loc.addClassSVG(enabledClass);

						//z-index
						businessIndex++;

						//create the popover
						var $popover = $('<a class="commercialMap-popover" href="'+businessInfo.url+'" style="z-index: '+businessIndex+';"><div class="commercialMap-popoverInner" style="background-image: url('+businessInfo.image+')" role="img" aria-label="'+businessInfo.title+'"></div></a>');
						$container.append($popover);
						$popover.data('loc', $loc);
						$popovers = $popovers.add($popover);

						//store a ref to the popover
						$loc.data('popover', $popover);

						//create a timer and store a ref to it
						var timer;
						$loc.data('timer', timer);

						//get the locations text, it if exists
						var $text = $svg.find('#text-'+businessInfo.location);
						$popover.data('position-el', $text.length > 0 ? $text : $loc);
					}
				}

				/**
				 * Removes all hover classes and closes all popovers.
				 */
				var deactivateAllLocations = function() {
					$locs.each(function (ind, el) {
						deactivateLocation($(this));
					})
				};

				var activateLocation = function($loc) {
					$loc.addClassSVG(activeClass);
					var $popover = $loc.data('popover');
					$popover.addClass(popoverOpenClass);
					var timer = $loc.data('timer');
					clearTimeout(timer);
				};

				var deactivateLocation = function($loc) {
					$loc.removeClassSVG(activeClass).removeClassSVG(hoverClass);
					var $popover = $loc.data('popover');
					$popover.removeClass(popoverOpenClass).removeClass(popoverHoverClass);
					var timer = $loc.data('timer');
					clearTimeout(timer);
				};

				var deHoverAllLocations = function() {
					$locs.each(function (ind, el) {
						deHoverLocation($(this));
					})
				};

				var hoverLocation = function($loc) {
					deHoverAllLocations();
					$loc.addClassSVG(hoverClass);
					var $popover = $loc.data('popover');
					$popover.addClass(popoverHoverClass);
				};

				var deHoverLocation = function($loc) {
					$loc.removeClassSVG(hoverClass);
					var $popover = $loc.data('popover');
					$popover.removeClass(popoverHoverClass);
				};

				/**
				 * Positions the popovers.
				 */
				var positionPopovers = function() {
					$popovers.each(function(ind, el) {

						var $popover = $(el);

						//get the popover's location
						var $posEl = $popover.data('position-el');

						if ($posEl.length) {
							//container offset
							var containerPos = $container.offset();

							//loc position
							var elBox = $posEl.get(0).getBoundingClientRect();

							var newLeft = elBox.left + window.scrollX + (elBox.width * .5 ) - containerPos.left;
							var newTop = elBox.top + window.scrollY - containerPos.top;

							//position the popover
							$popover.css({
								left: newLeft,
								top: newTop
							});
						}
					})
				};

				//trigger an initial positioning
				window.setTimeout(function () {
					positionPopovers();
				}, 50);

				var resizeHandler = function() {
					positionPopovers();

					//re-init chosen if needed
					var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
					if ( viewportWidth >= 800 ) {
						$businessSelect.chosen('destroy').chosen(chosenOptions);
						$categorySelect.chosen('destroy').chosen(chosenOptions);
					}
				};

				$window.on('DOMContentLoaded load resize', debounce(resizeHandler, 250, false) );

				//hover events
				$locs
					.mouseenter(function() {
						var $loc = $(this);
						if (isHoverMode) {
							activateLocation($loc);
						} else {
							hoverLocation($loc)
						}
					})
					.mouseleave(function() {
						var $loc = $(this);
						if (isHoverMode) {
							var timer = setTimeout(function () {
								deactivateLocation($loc);
							}, 10);
							$loc.data('timer', timer);
						} else {
							deHoverLocation($loc);
						}
					})
					.click(function(){
						//deactivate all locations
						deactivateAllLocations();

						//activate this location
						activateLocation($(this));

						//enter hover mode
						isHoverMode = true;

						//set the selects to empty
						$businessSelect.val('').trigger('chosen:updated');
						$categorySelect.val('').trigger('chosen:updated');
					});

				$popovers
					.mouseenter(function() {
						var $popover = $(this);
						var $loc = $popover.data('loc');
						if (isHoverMode) {
							activateLocation($loc);
						} else {
							hoverLocation($loc);
						}
					})
					.mouseleave(function() {
						var $popover = $(this);
						var $loc = $popover.data('loc');
						if (isHoverMode) {
							var timer = setTimeout(function () {
								deactivateLocation($loc);
							}, 10);
							$loc.data('timer', timer);
						} else {
							deHoverLocation($loc);
						}
					});

				//select change events
				$categorySelect.change(function(e) {
					$businessSelect.val('').trigger('chosen:updated');
					var slug = $(this).val();
					isHoverMode = ! slug;

					//deactivate all locations
					deactivateAllLocations();

					if (slug) {
						//select locations that have this category
						var ind = 0;
						for (var key in data.businesses) {
							if (data.businesses.hasOwnProperty(key)) {
								var businessInfo = data.businesses[key];
								if (businessInfo.cats.indexOf(slug) > -1) {
									var $loc = $svg.find('#loc-'+businessInfo.location);

									//activate this location
									activateLocation($loc);

									if (ind === 0) {
										//scroll into view
										setTimeout(function () {
											$loc.get(0).scrollIntoView({
												behavior: 'smooth'
											});
										}, 50);
									}

									ind += 1;
								}
							}
						}
					}
				});
				$businessSelect.change(function(e) {
					$categorySelect.val('').trigger('chosen:updated');
					var slug = $(this).val();
					isHoverMode = ! slug;

					//deactivate all locations
					deactivateAllLocations();

					if (slug) {
						//find this location
						var businessInfo = data.businesses[slug];
						var $loc = $svg.find('#loc-'+businessInfo.location);

						//activate this location
						activateLocation($loc);

						//scroll into view
						setTimeout(function () {
							$loc.get(0).scrollIntoView({
								behavior: 'smooth'
							});
						}, 50);
					}
				});

				//set up chosen when it's ready
				head.ready('chosen', function(){
					$categorySelect.chosen(chosenOptions);
					$businessSelect.chosen(chosenOptions);
				});
			});
		}
	},
	switcherTabs: function() {
		var $switchers = $('[data-component=switcher]');
		var selectedClass = 'switcherTab--selected';
		var blockInactiveClass = 'switcherTabBlock--inactive';

		if ($switchers.length) {
			$switchers.each(function () {
				var $switcher = $(this);
				var $tabs = $switcher.find('.switcherTab');
				var $blocks = $([]);
				var selectedIndex;
				var selectIndex = function(ind) {
					selectedIndex = ind;
					$blocks.addClass(blockInactiveClass).eq(ind).removeClass(blockInactiveClass);
					$tabs.removeClass(selectedClass).eq(ind).addClass(selectedClass);
				};

				if ($tabs.length) {
					$blocks = $switcher.find('~ .block').slice(0, $tabs.length);
					if ($blocks.length === $tabs.length) {
						$switcher.addClass('switcherTabs--ready');
						$blocks.addClass('switcherTabBlock');
						selectIndex(0);
						$tabs.click(function(e){
							e.preventDefault();
							selectIndex($tabs.index($(this)));
						});
					}
				}
			})
		}
	},
	init: function(){
		MG.components.clusterCarousel();
		MG.components.featuresSlider();
		MG.components.heroSlider();
		MG.components.videoLightboxes();
		MG.components.listingLightboxes();
		MG.components.brokerContactForm();
		MG.components.imageSlider();
		MG.components.threeColumnImages();
		MG.components.sidr();
		MG.components.siteMap();
		MG.components.commercialMap();
		MG.components.switcherTabs();
	}
};

/**
 * Setup methods to run before the DOM Init function.
 * @type {{featureDetection: Function, consoleLogging: Function}}
 */
MG.setup = {
	/**
	 * Sets up the js classes
	 */
	jsClasses : function() {
		$('html').removeClass('no-js').addClass('js');
	},
	/**
	 * Check for bg blend mode
	 * @see https://gist.github.com/markhowellsmead/5f8360c99b22b86f1dc6eae164583014
	 */
	featureDetection : function(){
		if (typeof window.getComputedStyle(document.body).mixBlendMode === 'undefined') {
			$('html').addClass('bg-blend-mode');
		}
	}
};

MG.init = function() {
	//setup
	MG.setup.jsClasses();
	MG.setup.featureDetection();

	//log the config
	//log(MG);

	//handle dom ready event
	$(function domReady() {
		//initialize components
		MG.components.init();
	});
};
MG.init();


MG.analytics = {};

MG.analytics.gtag_conversion_contactRealtor = function(url) {
    gtag('event', 'conversion', {'send_to': 'AW-755160822/_v9mCPv_05cBEPati-gC'});
    return true;
};
